<template>
  <div class="delegation-detail">
    <h2 class="delegation-detail__title">
      {{ isCreateMode ? 'Создание' : 'Изменение' }} правила делегирования
    </h2>
    <validation-observer
      ref="form"
      class="delegation-detail__form"
    >
      <form>
        <div class="delegation-detail__row">
          <div class="delegation-detail__item delegation-detail__item--wide">
            <div
              :class="['delegation-detail__label',{'delegation-detail__label--empty':!delegation.formattedLogin}]"
            >
              Делегировать полномочия на сотрудника
            </div>
            <validation-provider
              v-slot="v"
              name="Поиск сотрудников"
              :rules="!isEditMode ? `required|delegation:${selectedUserLogin},${fromLogin}` : ''"
            >
              <esmp-select
                ref="selectClient"
                class="delegation-detail__search-user"
                clearable
                placeholder="Поиск сотрудников"
                filterable
                :loading="loading"
                :show-search-results="showSearchResults"
                :remote-method="waitClientPrint"
                @on-query-change="setQuery"
                @on-select="setClientSelectedVal"
                @keydown.enter.native="searchClient"
                :disabled="isEditMode"
                :error-message="v.errors.length ? v.errors[0] : ''"
              >
                <esmp-select-option
                  v-for="r in clientSearchList"
                  :value="r.login"
                  :label="r.fullName"
                  :key="r.login"
                >
                  <div class="delegation-detail__item">
                    <div class="delegation-detail__user-contact">
                      <span class="delegation-detail__user-name">{{ r.fullName }}</span>
                      <span class="delegation-detail__user-email" v-if="r.email">{{ r.email }}</span>
                    </div>
                    <span class="delegation-detail__user-appointment" v-if="r.appointmentName">
                      {{ r.appointmentName }}
                    </span>
                  </div>
                </esmp-select-option>
              </esmp-select>
            </validation-provider>
          </div>
          <div class="delegation-detail__item delegation-detail__item--dates">
            <div
              :class="[
                // eslint-disable-next-line max-len
                'delegation-detail__label', {'delegation-detail__label--empty': !delegation.dateFrom || !delegation.dateTo}]"
            >
              Период замещения
            </div>
            <div class="delegation-detail__dates">
              <validation-provider
                v-slot="v"
                name="Дата начала"
                rules="required"
                mode="passive"
              >
                <esmp-datepicker-adaptive
                  ref="selectedStartDate"
                  :key="delegation.dateTo ? delegation.dateTo.getTime() : '' || endTime"
                  v-model="delegation.dateFrom"
                  placeholder="Дата начала"
                  is-time-selectable
                  :time-interval="timeInterval"
                  clearable
                  :class="[
                    'delegation-detail__date',
                    {'delegation-detail__date--error': !isDatesValidated || v.errors.length}]"
                  :disabled-dates="minStartDate"
                  :end-time="endTime"
                  @select-date="setDateStart('selectedStartDate')"
                />
                <span v-if="v.errors.length" class="delegation-detail__error typo-caption">
                  {{ v.errors[0] }}
                </span>
              </validation-provider>
              <validation-provider
                v-slot="v"
                name="Дата окончания"
                rules="required"
                mode="passive"
                :vid="vid"
              >
                <esmp-datepicker-adaptive
                  ref="selectedFinishDate"
                  :key="delegation.dateFrom ? delegation.dateFrom.getTime() : '' || startTime"
                  v-model="delegation.dateTo"
                  placeholder="Дата окончания"
                  is-time-selectable
                  :time-interval="timeInterval"
                  clearable
                  :class="[
                    'delegation-detail__date',
                    {'delegation-detail__date--error': !isDatesValidated || v.errors.length}]"
                  :disabled-dates="minEndDate"
                  :disabled="isEditMode"
                  :start-time="startTime"
                  @select-date="setDateStart('selectedFinishDate')"
                />
                <span v-if="v.errors.length" class="delegation-detail__error typo-caption">
                  {{ v.errors[0] }}
                </span>
              </validation-provider>
            </div>
          </div>
        </div>
        <div class="delegation-detail__row">
          <div class="delegation-detail__item delegation-detail__item--wide">
            <div :class="['delegation-detail__label', {'delegation-detail__label--empty': !delegation}]">
              Услуги для делегирования
            </div>
            <validation-provider
              v-slot="v"
              name="Дата окончания"
              rules="required"
              mode="passive"
            >
              <esmp-select
                v-model="delegation.ruleType"
                placeholder="Услуги для делегирования"
                :error-message="v.errors.length ? v.errors[0] : ''"
              >
                <esmp-select-option
                  v-for="item in exceptions"
                  :value="item"
                  :key="item.id"
                >
                  {{ item.name }}
                </esmp-select-option>
              </esmp-select>
            </validation-provider>
          </div>
          <div class="delegation-detail__item delegation-detail__item--align-end">
            <esmp-button
              class="delegation-detail__add"
              view="ghost"
              icon="plus"
              icon-position="left"
              :disabled="isRuleTypeForAll"
              @click="showExeptionModal"
            >
              {{ isRuleTypeExclude ? 'Исключить' : 'Добавить' }} услугу
            </esmp-button>
          </div>
        </div>
        <div class="delegation-detail__row" v-if="delegation.rules.length">
          <div class="delegation-detail__list">
            <div
              class="delegation-detail__rule"
              v-for="rule in delegation.rules"
              :key="rule.id"
            >
              <span>{{ rule.name || rule.serviceName }}</span>
              <esmp-button
                view="function"
                icon="trash"
                @click="deleteRule(rule)"
              />
            </div>
          </div>
        </div>
        <div class="delegation-detail__row">
          <esmp-switch
            :class="['delegation-detail__label', {'delegation-detail__label--empty': !delegation.isActive}]"
            v-model="delegation.isActive"
            label="Активировать делегирование"
            label-position="left"
          />
        </div>
      </form>
      <div :class="['page__action-buttons', { 'page__action-buttons--wide': isCollapsedMenu}]">
        <esmp-button
          class="page__action-button"
          type="submit"
          @click="onSubmit"
        >
          Применить правило
        </esmp-button>
        <esmp-button
          v-if="isEditMode"
          class="page__action-button"
          view="outline"
          @click="prolongDelegation"
        >
          Продлить
        </esmp-button>
        <esmp-button
          class="page__action-button"
          view="outline"
          @click="cancel"
        >
          Отмена
        </esmp-button>
      </div>
      <exception-modal
        :is-showed-exception-modal="isShowedExceptionModal"
        @hide-modal="hideModal"
        @select-rule="addRule"
      />
      <active-delegation-modal
        :is-showed-active-delegation-modal="isShowedActiveDelegationModal"
        @hide-active-delegation-modal="hideActiveDelegationModal"
        @active-delegation="activeDelegation"
      />
    </validation-observer>
  </div>
</template>

<script>
import uid from '@/components/hm-form/mixins/uid';
import { mapState, mapGetters, mapActions } from 'vuex';
import { defaults, cloneDeep } from 'lodash';
import dayjs from 'dayjs';
import { SEARCH_DELAY } from '@/constants/search';
import ActiveDelegationModal from './active-delegation-modal.vue';

const ExceptionModal = () => import('./exception-modal.vue');
const exceptions = {
  all: {
    id: 'all',
    name: 'Все услуги',
  },
  include: {
    id: 'include',
    name: 'Выберите услуги',
  },
  exclude: {
    id: 'exclude',
    name: 'Все услуги, кроме',
  },
};

const oneDay = 24 * 60 * 60 * 1000;
const timeInterval = 30;

export default {
  name: 'DelegationDetail',
  mixins: [uid],
  components: {
    ExceptionModal,
    ActiveDelegationModal,
  },
  data() {
    return {
      isShowedExceptionModal: false,
      isShowedActiveDelegationModal: false,
      loading: false,
      showSearchResults: false,
      searchTimeoutId: null,
      clientSearchList: null,
      query: '',
      prolongationDelegation: null,
      delegation: {
        rules: [],
        isActive: false,
        dateFrom: null,
        dateTo: null,
        fromFullName: null,
        fromLogin: null,
        id: null,
        toFullName: null,
        toLogin: null,
      },
      selectedUserLogin: null,
      minStartDate: (date) => {
        if (this.isEditMode) {
          return date.getTime() >= this.delegation.dateTo.getTime();
        }
        if (this.delegation.dateTo) {
          return date.getTime() >= this.delegation.dateTo.getTime()
            || date.getTime() <= Date.now() - this.oneDay;
        }
        return date.getTime() <= Date.now() - this.oneDay;
      },
      minEndDate: (date) => {
        if (this.delegation.dateFrom) {
          return (date.getTime() <= this.delegation.dateFrom.getTime() - this.oneDay);
        }
        return (date.getTime() <= Date.now() - this.oneDay);
      },
      oneDay: null,
      timeInterval: null,
      startTime: null,
      endTime: null,
      selectedStartDate: null,
      selectedFinishDate: null,
    };
  },
  watch: {
    selectedStartDate(val) {
      if (val && this.selectedFinishDate && dayjs(val).valueOf() === dayjs(this.selectedFinishDate).valueOf()) {
        this.endTime = this.$refs?.selectedFinishDate?.selectedTime;
      } else {
        this.endTime = null;
      }
    },
    selectedFinishDate(val) {
      if (val && this.selectedStartDate && dayjs(val).valueOf() === dayjs(this.selectedStartDate).valueOf()) {
        this.startTime = this.$refs?.selectedStartDate?.selectedTime + (this.timeInterval * 60 * 1000);
      } else {
        this.startTime = null;
      }
    },
  },
  computed: {
    ...mapState('sidebar', ['isCollapsedMenu']),
    ...mapGetters('user', ['selectedOrLoggedInUser']),
    isEditMode() {
      return !!this.delegation.id;
    },
    isCreateMode() {
      return !this.isEditMode;
    },
    isRequiredRules() {
      return this.delegation.ruleType && this.delegation.ruleType.id !== 'all';
    },
    isRulesValidated() {
      if (this.isRequiredRules) return !!this.delegation.rules.length;
      return true;
    },
    isRuleTypeExclude() {
      return (
        this.delegation && this.delegation.ruleType && this.delegation.ruleType.id === this.exceptions.exclude.id
      );
    },
    isRuleTypeForAll() {
      return (
        this.delegation && this.delegation.ruleType && this.delegation.ruleType.id === this.exceptions.all.id
      );
    },
    isDatesValidated() {
      if (this.delegation.dateFrom && this.delegation.dateTo) {
        return this.delegation.dateFrom.getTime() <= this.delegation.dateTo.getTime();
      }
      return true;
    },
    fromLogin() {
      return this.delegation.fromLogin || this.selectedOrLoggedInUser.login;
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    setDateStart(currentDate) {
      this[currentDate] = this.$refs[currentDate]?.selectedDate;
    },
    cancel() {
      this.$router.go(-1);
    },
    showExeptionModal() {
      this.isShowedExceptionModal = true;
    },
    showActiveDelegationModal() {
      this.isShowedActiveDelegationModal = true;
    },
    hideModal() {
      this.isShowedExceptionModal = false;
    },
    setClientSelectedVal(val) {
      if (this.clientSearchList && this.clientSearchList.length) {
        this.delegation.formattedLogin = this.clientSearchList.find((el) => el.login === val.value);
        if (this.delegation?.formattedLogin?.login) {
          this.setUserData(this.delegation.formattedLogin.login);
        }
      }
    },
    waitClientPrint() {
      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);

      if (!this.query || this.query.length < 3) {
        this.showSearchResults = false;
      }
      if (this.query && this.query.length >= 3) {
        this.showSearchResults = true;
        this.loading = true;
        this.searchTimeoutId = setTimeout(() => {
          this.searchClient();
        }, SEARCH_DELAY);
      } else {
        this.loading = false;
      }
    },
    async searchClient() {
      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);
      if (this.query) {
        this.loading = true;
        const { data } = await this.$API.user.search({
          query: this.query,
        });
        this.clientSearchList = data;
        this.loading = false;
      }
    },
    setQuery(val) {
      this.query = val;
    },
    addRule(newRule) {
      if (
        !this.delegation.rules.find((item) => item.id === newRule.id)
      ) {
        this.delegation.rules.push(newRule);
      } else {
        this.$EsmpNotify.$show('Данная услуга уже добавлена', 'error');
      }
    },
    deleteRule(rule) {
      this.delegation.rules = this.delegation.rules.filter(
        (item) => item.id !== rule.id,
      );
    },
    onSubmit() {
      this.$refs.form.validate().then((res) => {
        if (this.isRulesValidated && this.isDatesValidated && res) {
          if (this.isCreateMode) {
            if (!this.delegation.isActive) {
              this.showActiveDelegationModal();
            } else {
              this.create();
            }
          } else {
            this.save();
          }
        }
      }).catch(() => {
        this.$EsmpNotify.$show('Введенные данные не валидны и/или не выбраны услуги', 'error');
      });
    },
    create() {
      this.setLoading({ key: 'page', value: true });
      const newDelegation = this.formatDelegationDataToDTO();
      this.$API.delegation.createDelegation(newDelegation).then(() => {
        this.$EsmpNotify.$show('Делегирования успешно создано.', 'success');
        this.$router.push({ name: 'Delegations' });
        this.setLoading({ key: 'page', value: false });
      }).catch(() => {
        this.$EsmpNotify.$show('Ошибка при создании делегирования', 'error');
        this.setLoading({ key: 'page', value: false });
      });
    },
    save() {
      this.setLoading({ key: 'page', value: true });
      const newDelegation = this.formatDelegationDataToDTO();
      this.$API.delegation.updateDelegation(newDelegation.id, newDelegation)
        .then(() => {
          this.$EsmpNotify.$show('Изменения делегирования успешно сохранены', 'success');
          this.$router.push({ name: 'Delegations' });
          this.setLoading({ key: 'page', value: false });
        })
        .catch(() => {
          this.$EsmpNotify.$show('Ошибка при изменении делегирования', 'error');
          this.setLoading({ key: 'page', value: false });
        });
    },
    setUserData(login) {
      this.selectedUserLogin = login;
    },
    formatDelegationDataForComponent(data = {}) {
      const defaultDelegation = {
        dateFrom: null,
        dateTo: null,
        fromFullName: null,
        fromLogin: null,
        id: null,
        isActive: false,
        rules: [],
        toFullName: null,
        toLogin: null,
      };
      const ruleType = data && typeof data.ruleType === 'string'
        ? this.exceptions[data.ruleType]
        : this.exceptions.all;
      const localDelegation = defaults({ ruleType }, data, defaultDelegation);

      localDelegation.formattedLogin = {
        name: localDelegation.toFullName,
        login: localDelegation.toLogin,
      };

      if (localDelegation.toLogin) {
        this.setUserData(localDelegation.toLogin);
        this.$refs.selectClient.$emit('on-select-selected', {
          value: localDelegation.formattedLogin.login,
          label: localDelegation.formattedLogin.name,
          tag: localDelegation.formattedLogin.login,
        });
      }

      if (localDelegation.dateFrom) localDelegation.dateFrom = new Date(localDelegation.dateFrom);
      if (localDelegation.dateTo) localDelegation.dateTo = new Date(localDelegation.dateTo);

      return localDelegation;
    },
    formatDelegationDataToDTO() {
      const dto = cloneDeep(this.delegation);

      if (this.isCreateMode) {
        dto.toLogin = this.delegation.formattedLogin.toLogin
          || this.delegation.formattedLogin.login;
        dto.toFullName = this.delegation.formattedLogin.fullName
          || this.delegation.formattedLogin.name;
      }
      dto.fromLogin = dto.fromLogin || this.selectedOrLoggedInUser.login;
      dto.fromFullName = dto.fromFullName || this.selectedOrLoggedInUser.fullName;
      dto.ruleType = this.delegation.ruleType.id;
      dto.dateFrom = dayjs(this.delegation.dateFrom).valueOf();
      dto.dateTo = dayjs(this.delegation.dateTo).valueOf();
      dto.isActive = this.delegation.isActive;

      if (this.delegation.ruleType.id === this.exceptions.all.id) {
        dto.rules = [];
      } else {
        dto.rules = this.delegation.rules.map((rule) => (
          {
            delegationId: this.delegationId,
            serviceId: rule.serviceId || rule.id,
            serviceName: rule.serviceName || rule.name,
            sourceSystem: rule.sourceSystem,
            isActive: this.delegation.isActive,
          }
        ));
      }

      delete dto.formattedLogin;

      return dto;
    },
    async init() {
      const delegationId = this.$route.params.id;

      if (delegationId) {
        this.setLoading({ key: 'page', value: true });
        const { data } = await this.$API.delegation.getDelegationById(delegationId);
        this.prolongationDelegation = data;
        this.delegation = this.formatDelegationDataForComponent(
          data,
        );
        this.$router.setMetaOption(this.$route, 'title', this.delegation.id);
        this.setLoading({ key: 'page', value: false });
      } else {
        this.delegation = this.formatDelegationDataForComponent();
      }
    },
    prolongDelegation() {
      this.$emit('toggle-modal', {
        modalName: 'ModalDelegationProlongation',
        modalState: true,
        delegation: this.prolongationDelegation,
      });
    },
    activeDelegation(val) {
      this.delegation.isActive = val;
      this.create();
    },
    hideActiveDelegationModal() {
      this.isShowedActiveDelegationModal = false;
    },
  },
  created() {
    this.exceptions = exceptions;
    this.oneDay = oneDay;
    this.timeInterval = timeInterval;
    this.init();
  },
};
</script>

<style lang="scss">
  .delegation-detail {
    background-color: $color-white;
    border-radius: 16px;
    padding: 32px;

    @include for-size(phone-landscape-down) {
      padding: 24px;
    }

    @include for-size(phone-portrait-down) {
      padding: 20px;
      width: 100vw;
      margin-left: -20px;
    }

    &__title {
      font-size: 22px;
      line-height: 24px;
      margin-bottom: 17px;

      @include for-size(phone-landscape-down) {
        font-size: 20px;
      }

      @include for-size(phone-portrait-down) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    &__row {
      display: flex;
      align-items: flex-start;

      @include for-size(phone-landscape-down) {
        flex-wrap: wrap;
      }

      &:nth-of-type(1) {
        padding-bottom: 24px;
        border-bottom: 1px solid $color-grayscale-05;

        @include for-size(phone-portrait-down) {
          padding-bottom: 16px;
        }
      }

      &:not(:last-child) {
        margin-bottom: 24px;

        @include for-size(phone-portrait-down) {
          margin-bottom: 16px;
        }
      }
    }

    &__item {

      &:not(:last-child) {
        margin-right: 32px;

        @include for-size(phone-landscape-down) {
          margin-right: 14px;
        }

        @include for-size(phone-portrait-down) {
          margin-right: 0;
        }
      }

      &--wide {
        width: 440px;

        @include for-size(phone-portrait-down) {
          width: 100%;
        }
      }

      &--dates {
        @include for-size(phone-landscape-down) {
          width: 100%;
          margin-top: 16px;
        }
      }

      &--align-end {
        align-self: flex-end;
      }
    }

    &__label {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 4px;

      @include for-size(phone-portrait-down) {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 6px;
      }

      &--empty {
        color: $color-black-op-25;
      }
    }

    &__dates {
      display: flex;

      @include for-size(phone-portrait-down) {
        justify-content: space-between;

        span {
          display: block;
          width: calc(100% - 10px);
        }
      }
    }

    &__date {

      &:first-child {
        margin-right: 16px;

        @include for-size(phone-portrait-down) {
          margin-right: 0;
        }
      }

      &:last-child {
        @include for-size(phone-portrait-down) {
          .esmp-button-wrapper {
            margin-left: auto;
          }
        }
      }

      @include for-size(phone-portrait-down) {
        width: calc(100% - 10px);

        .esmp-input-icon--button {
          display: none;
        }

        .esmp-input-icon--clear-button {
          right: 12px;
        }

        .esmp-input-element {
          font-size: 12px;
          padding-right: 30px;
        }
      }

      &--error {
        .esmp-input-label {
          color: $esmp-input-error-color;
        }
      }
    }

    &__add {
      @include for-size(phone-landscape-down) {
        padding: 0;
      }
      @include for-size(phone-portrait-down) {
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }

    &__user-contact {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    &__user-name {
      margin-right: 16px;
      font-size: 14px;
      line-height: 20px;
      color: $color-black;
      transition: color $base-animation-time;
    }

    &__user-email {
      font-size: 12px;
      line-height: 16px;
      color: $color-black-op-50;
      margin-right: 16px;
    }

    &__user-appointment {
      font-size: 12px;
      line-height: 16px;
      color: $color-black-op-40;
    }

    .esmp-switch__label {
      font-size: 16px;
      line-height: 24px;
    }

    &__list {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    &__rule {
      width: 100%;
      padding: 22px 24px;
      border-radius: 12px;
      border: 1px solid $color-black-op-10;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include for-size(phone-portrait-down) {
        padding: 12px 14px;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    &__error {
      color: $esmp-input-error-color;
      word-break: break-word;
      display: inline-block;
      padding: 0 $base-gutter;
      margin-top: $base-gutter / 1.5;
      margin-bottom: $base-gutter / 1.5;
    }
  }
</style>
