<template>
  <esmp-modal
    v-model="show"
    title="Активировать делегирование?"
    ok-text="Активировать"
    cancel-text="Нет"
    class-name="active-delegation-modal"
    @on-ok="activeDelegation(true)"
    @on-cancel="activeDelegation(false)"
  >
    <p>Делегирование будет активировано при создании</p>
  </esmp-modal>
</template>

<script>
export default {
  name: 'ActiveDelegationModal',
  props: {
    isShowedActiveDelegationModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    show: {
      get() {
        return this.isShowedActiveDelegationModal;
      },
      set(newValue) {
        this.$emit('hide-active-delegation-modal', newValue);
      },
    },
  },
  methods: {
    activeDelegation(val) {
      this.$emit('active-delegation', val);
    },
  },
};
</script>
